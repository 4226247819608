import ContactSection from "../../Components/Organisms/Contact";

const Contato = () => {
    return (
        <div>
            <ContactSection/>
        </div>
    )
};

export default Contato;