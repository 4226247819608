import ServiceGrid from "../../Components/Organisms/Fotos";

const Fotos = () => {
    return (
        <div>
            <ServiceGrid/>
        </div>
    )
};

export default Fotos;