import AllServices from "../../Components/Molecules/AllServices";

const Servicos = () => {
    return (
        <div>
            <AllServices/>
        </div>
    )
};

export default Servicos;
