import React from "react";
import styles from './index.module.scss'

const Direitos = () =>{
    return(
        <div className={styles.direitos}>
            <p>© 2024 Gran Prisma. Todos os direitos reservados.</p>
        </div>
    )

}

export default  Direitos;